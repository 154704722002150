import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png"
import ProfileLogo from "../../assets/images/profile-logo.png"
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Spinner, Tooltip } from "react-bootstrap";
import { connectToMetamask, onLoggingIn } from '../../redux-store/actions'
import { claimToken,EstimateGas } from '../../web3/web3'
import { Scrollbars } from "react-custom-scrollbars";
import { login, claimtokens, updatetoken, userDetails } from '../../APi/authactions'
import { removeAuthorization } from '../../common-config/axios.config'
import ProfileCompnent from '../components/profile'
import config from "../../common-config/config"
import Web3 from "web3"



const Navbar = (props) => {
  const [loading, setloading] = useState(false)
  const [zoro, setzoro] = useState(0);
  const navigate = useNavigate();
  const [copytxt, setcopytxt] = useState(false)
  const [profileData, setprofileData] = useState({});

  const Logout = (async () => {
    props.onLogin({ isLoggedIn: false })
    sessionStorage.removeItem('operon_token')
    sessionStorage.removeItem('zoro')
    removeAuthorization()
    window.location.reload(true)
    navigate('/login')
  })
  useEffect(() => {
    // let zoro = sessionStorage.getItem('zoro')
    getDetails();
    console.log(typeof zoro, "typeof")
  }, [])

  const getDetails = (async () => {
    let { result } = await userDetails();
    setprofileData(result);
    setzoro(result.zoro_coins);
    console.log(result, "resulst")
  })


  const claim = (async () => {
    try {
      let web3 = new Web3(window.ethereum);
     let netId = await web3.eth.net.getId();
        if (netId !== config.NETWORK) {
          toastr.warning("please switch binance Mainnet")
          return false;
        }
      if (profileData.zoro_coins <= 0) {
        toastr.error("You don't have zoro token")
        return;
      }
      setloading(true)
      let { success, result, message } = await claimtokens();
      if (success) {
        let { sign, zoro } = JSON.parse(result);
        console.log(sign, "....sign")
        let signdata = {
          _mrs: sign._mrs,
          _v: sign._v,
          amount: zoro
        }
        let {balance,gasAmount} = await EstimateGas(signdata);
        console.log(parseFloat(balance),gasAmount,"details")
        if(gasAmount>parseFloat(balance)){
        setloading(false)
        toastr.warning("You'r BNB balance is too low!!!");
        return false;
        }
        console.log(signdata, "signdata");
        let myclaim = await claimToken(signdata);
        console.log(myclaim, "contract result")
        setzoro(0);
        let reqdata = { zoro_coins: -zoro, nft_card_id: 0 };
        let updatezoro = await updatetoken(reqdata)
        setloading(false)
        sessionStorage.setItem('zoro', 0)
        getDetails();
        toastr.success("Rewards claimed successfully!!!")
      } else {
        setloading(false)
        toastr.error(message)
      }
    } catch (err) {
      setloading(false)
      toastr.error("Rewards claimed failed!!!")
      console.log(err, "error")
    }
  })
  return (
    <nav className="navbar navbar-expand-lg main_navbar">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={Logo} alt="Logo" className="logo_img" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/home">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/profile">
                Profile
              </NavLink>
            </li>
          </ul> */}
          <ul className="navbar-nav mb-2 mb-lg-0 navbar_right ms-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle wallet_address"
                href="#"
                id="navbarDropdown"
                data-bs-auto-close="outside"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* {sessionStorage.getItem('address')} */}
                {profileData && profileData.image === "" ? <img src={ProfileLogo} alt="Logo" className="nav-profile_img" /> : <img src={`${config.IMAGE_URL}profile/${profileData.image}`} alt="Logo" className="nav-profile_img" />} {sessionStorage.getItem('address')}
              </a>
              <ul className="dropdown-menu wallet_dropdown dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li className="profile-drop">

                  <ProfileCompnent />
                </li>
                <li>
                  {/* <a onClick={() => {
                    copy(sessionStorage.getItem('address'));
                    setcopytxt(true);
                    setTimeout(function () {
                      setcopytxt(false);
                    }, 800)
                  }} className="dropdown-item" href="#">
                    Copy
                  </a> */}
                </li>
                <li onClick={Logout}>
                  <a className="dropdown-item" href="#">
                    Logout
                  </a>
                </li>
                {/* <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li> */}
              </ul>
            </li>

            <li className="nav-item dropdown">

              <a
                className="nav-link dropdown-toggle token_div"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {loading ? <Spinner animation="border" size="sm" /> : <span>{profileData.zoro_coins} Zoro</span>}
              </a>
              <ul className="dropdown-menu dropdown-menu-end wallet_dropdown" aria-labelledby="navbarDropdown">
                {zoro && <li onClick={loading ? "" : claim}>
                  <a className="dropdown-item" href="#">
                    Claim
                  </a>
                </li>

                }

              </ul>
            </li>

          </ul>
          {/* <div className="token_div">
            103 ZORO
          </div>
          <button className="btn claim_btn" type="submit">
            Claim
          </button> */}
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(onLoggingIn(data)),
    onConnectToMetamask: () => dispatch(connectToMetamask())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
