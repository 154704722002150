import isEmpty from '../../helper/isEmpty'
export const forgotvalidate = async (data) => {
    let errors = {}, reqBody = data;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(reqBody.email)) {
        errors.email = "Email Required";
    } else if (!(emailRegex.test(reqBody.email))) {
        errors.email = "Email Invalid";
    }
    if (isEmpty(reqBody.address)) {
        errors.address = "Address Required";
    }

    if (!isEmpty(errors)) {
        return {'status':false,'errors':errors}
    }else{
        return {'status':true,'errors':''}
    }
  };

  export const Resetvalidate = async (data) => {
    let errors = {}, reqBody = data;
    let lowercase = new RegExp("^(?=.*[a-z])");

    if (isEmpty(reqBody.password)) {
        errors.password = "Password Required";
    } else if (!(lowercase.test(reqBody.password))||!(lowercase.test(reqBody.confirmpassword))) {
        errors.password = "The Password must contain at least 1 lowercase alphabetical character";
    }

    if (isEmpty(reqBody.confirmpassword)) {
        errors.confirmpassword = "Confirm Password Required";
    }
    
    if (isEmpty(reqBody.address)) {
        errors.address = "Address Required";
    }
    if (reqBody.password !== reqBody.confirmpassword) {
        errors.comman = "password not match";
    }

    if (!isEmpty(errors)) {
        return {'status':false,'errors':errors}
    }else{
        return {'status':true,'errors':''}
    }
  };

  export const Loginvalidate = async (data) => {
    let errors = {}, reqBody = data;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(reqBody.email)) {
        errors.email = "Email Required";
    } else if (!(emailRegex.test(reqBody.email))) {
        errors.email = "Invalid Email";
    }
    if (isEmpty(reqBody.password)) {
        errors.password = "Password Required";
    }
    if (isEmpty(reqBody.address)) {
        errors.address = "Address Required";
    }

    if (!isEmpty(errors)) {
        return {'status':false,'errors':errors}
    }else{
        return {'status':true,'errors':''}
    }
  };


//   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

// RegEx	Description
// ^	The password string will start this way
// (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
// (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
// (?=.*[0-9])	The string must contain at least 1 numeric character
// (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
// (?=.{8,})	The string must be eight characters or longer
