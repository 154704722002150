import axios from "axios"
import config from "./config"

import { getAuthToken } from '../helper/localstorage'

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['authtoken'] = getAuthToken();

export const setAuthorization = (token) => {
    console.log(token,"token")
    axios.defaults.headers.common['authtoken'] = token;
}

export const removeAuthorization = () => {
    delete axios.defaults.headers.common["authtoken"];
}

export default axios;