import './Layout.css'
import { connect } from 'react-redux'
import { connectToMetamask } from "../../../redux-store/actions";
import Navbar from '../../components/navbar';

const Layout = (props) => {
    console.log(props.children,"routerpath")
    return (
        <>
            <Navbar />
            <div className="wrapper">
                <div className="dynamic-content">
                    {props.children}
                </div>
            </div>
        </>
    )
}


export default Layout;