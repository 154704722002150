import * as actions from './types'

const initialState = {
    networks: {
        main: {
            bsc: {
                name: 'BSC Mainnet',
                chainId: 56,
                tokenName: 'BUSD'
            },
            polygon: {
                name: 'POLYGON Mainnet',
                chainId: 137,
                tokenName: 'ETH'
            },
            avalanche: {
                name: 'Avalanche Mainnet',
                chainId: 43114,
                tokenName: 'AVAX'
            },
            ethereum: {
                name: 'Ethereum Mainnet',
                chainId: 1,
                tokenName: 'ETH'
            }
        },
        testnet: {
            bsc: {
                name: 'BSC Testnet',
                chainId: 97,
                tokenName: 'BUSD'
            },
            polygon: {
                name: 'POLYGON Testnet',
                chainId: 80001,
                tokenName: 'ETH'
            },
            avalanche: {
                name: 'Avalanche FUJI',
                chainId: 43113,
                tokenName: 'AVAX'
            },
            ethereum: {
                name: 'Ropsten Test Network',
                chainId: 3,
                tokenName: 'ETH'
            }
        }
    },
    account: {
        isConnected: false,
        isLoggedIn: false,
        authenticationToken: '',
        connectedNetwork: {
            name: '',
            chainId: 0
        },
        address: '',
        email: '',
        role: '',
        balance: 0,
        walletType: 'Metamask',
    },
    notification: {
        error: false,
        type: '',
        message: 'No Error'
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.ON_CONNECT_WALLET:
            return {
                ...state,
                account: {
                    ...state.account,
                    isConnected: action.payload.data.isConnected,
                    address: action.payload.data.address,
                    balance: action.payload.data.balance,
                    connectedNetwork: {
                        ...state.account.connectedNetwork,
                        name: action.payload.data.connectedNetwork.name,
                        chainId: action.payload.data.connectedNetwork.chainId,
                    }
                }
            }
        case actions.ON_LOGGING_IN:
            return {
                ...state,
                account: {
                    ...state.account,
                    isLoggedIn: action.payload.data.isLoggedIn,
                    email: action.payload.data.email,
                }
            }
        case actions.ON_LOGGING_OUT:
            return state = initialState
        default:
            return state
    }
}