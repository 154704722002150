let key 
if(process.env.NODE_ENV === "production"){
 key = {
    baseUrl: 'https://game.operonorigins.com/',
    API_URL: 'https://marketapi.operonorigins.com/apis/v1/',
    IMAGE_URL: 'https://marketapi.operonorigins.com/',
    GameUrl: 'https://game.operonorigins.com/Game/web-desktop',
    // GameUrl: 'http://54.160.165.123/web-desktop/index.html',
    GameContract: "0x60BC3b40edB0EA33EA784a56A976beB12cff6F0A",
    NETWORK: 56,
}
}else{
    key = {
        baseUrl: 'http://localhost:3000',
        API_URL: "http://localhost:2053/apis/v1/",
        IMAGE_URL: 'http://localhost:2053/',
        GameUrl: 'http://juego-build-server.s3.amazonaws.com/htmlBuilds/37b678819f59b314d9d8e9ffc5c52968/index.html',
        GameContract: "0x5187C849a07546fF761e3d53F9B1475a34E38774",
        NETWORK: 97,
    }
} 

export default key;
