import React from 'react'
import { connect } from 'react-redux'
import {Navigate } from 'react-router-dom';
import { onLoggingIn, OnLoggingOut } from '../redux-store/actions';

const ConditionRoute = ({children:children, type: type, ...props }) => {
    console.log(props.accounts.isLoggedIn,"elementtype");

    if (type === 'private' && !sessionStorage.getItem('operon_token')) {
      return( <Navigate to="/login" />)
    }else{
    return children;
    }

}

const mapStateToProps = (state) => {
    return {
      accounts: state.reducer.account || {}
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onLogin: (data) => dispatch(onLoggingIn(data)),
      onLoggout: () => dispatch(OnLoggingOut())
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(ConditionRoute);

