import './AuthLayout.css'
import { connect } from 'react-redux'
import { connectToMetamask } from "../../../redux-store/actions";

const Layout = (props) => {
    console.log(props.children,"routerpath")
    return (
        <>
            <div className="wrapper">
                <div className="dynamic-content">
                    {props.children}
                </div>
            </div>
        </>
    )
}


export default Layout;