import Web3 from 'web3';
import GameABI from '../ABI/GameABI.json'
import config from '../common-config/config'
import store from '../redux-store/store';
import { toastr } from 'react-redux-toastr';
import { removeAuthorization } from '../common-config/axios.config';

const ethereum = window.ethereum;
var web3 = new Web3(Web3.givenProvider || ethereum);



const contractAddress = {
    main: '',
    approve: ''
}

let contractInstance;
let walletAccount;
let isTestNetNetwork = false;


export const isMetaMaskInstalled = async () => {
    let ethereum = window.ethereum
    console.log('inner', ethereum);
    if (ethereum) {
        await ethereum.enable();
        return await checkConnectedNetwork()
    }
    return false;
}

const checkConnectedNetwork = async () => {
    let ethereum = window.ethereum;
    let web3 = new Web3(ethereum);

    if ((isTestNetNetwork ? store.getState().reducer.networks.testnet.bsc.chainId : store.getState().reducer.networks.main.bsc.chainId) === await web3.eth.getChainId()) {
        await subscribeToAccountNetworkChange('', '', '')
        return await connectWallet()
    } else {
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.numberToHex(isTestNetNetwork ? store.getState().reducer.networks.testnet.bsc.chainId.toString() : store.getState().reducer.networks.main.bsc.chainId.toString()) }], // chainId must be in hexadecimal numbers
            })
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x38',
                                chainName: 'BSC Mainnet',
                                nativeCurrency: {
                                    name: "BNB",
                                    symbol: "BNB", // 2-6 characters long
                                    decimals: 18,
                                  },
                                rpcUrls: ['https://bsc-dataseed1.binance.org/'] /* ... */,
                                blockExplorerUrls: ['https://bscscan.com/']
                            },
                        ],
                    });

                } catch (addError) {
                    // handle "add" error
                }
            }
        }
        await subscribeToAccountNetworkChange('', '', '')
        return await connectWallet()
    }
}

export const subscribeToAccountNetworkChange = (dispatch, network) => {
    var ethereum = window.ethereum;
    let web3 = new Web3(ethereum);
    console.log(ethereum,"ethereum123........123")
    if(window.ethereum){
    ethereum.on("accountsChanged", function (accounts) {
        sessionStorage.removeItem('operon_token')
        sessionStorage.removeItem('zoro')
        toastr.warning("Wallet account switched")
        removeAuthorization()
        window.location.replace(`${config.baseUrl}/login`)
    })
    ethereum.on('chainChanged', async () => {
        web3.eth.net.getId((err, netId) => {
            if (netId !== config.NETWORK) {
                toastr.warning("please switch binance Mainnet")
            }
        })
    });
}
}

const connectWallet = async () => {
    let ethereum = window.ethereum
    let web3 = new Web3(ethereum);
    walletAccount = await web3.eth.requestAccounts()
    if (walletAccount.length === 0) {
        return false
    }
    sessionStorage.setItem('address', walletAccount[0]);
    const payload = {
        account: {
            isConnected: true,
            connectedNetwork: {
                name: isTestNetNetwork ? store.getState().reducer.networks.testnet.bsc.name : store.getState().reducer.networks.main.bsc.name,
                chainId: store.getState().reducer.networks.testnet.bsc.chainId
            },
            address: walletAccount[0],
            balance: await web3.eth.getBalance(walletAccount[0])
        },
        notification: {
            error: false,
            type: 'success',
            message: `Connected to ${isTestNetNetwork ? store.getState().reducer.networks.testnet.bsc.name : store.getState().reducer.networks.main.bsc.name} Network`
        }
    }
    return payload
}

export const claimToken = async (data) => {
    let ethereum = window.ethereum
    let web3 = new Web3(ethereum);
    var GameContract = new web3.eth.Contract(GameABI, config.GameContract);
    let value = (data.amount * 1e18).toString();
    console.log(value, "value")
    walletAccount = await web3.eth.requestAccounts();
    var result = await GameContract.methods.claimOperonToken(walletAccount[0], value, data._mrs, data._v).send({ from: walletAccount[0] });
    return result;
}

export const EstimateGas = async (data) => {
    let ethereum = window.ethereum
    let web3 = new Web3(ethereum);

    var GameContract = new web3.eth.Contract(GameABI, config.GameContract);
    let value = (data.amount * 1e18).toString();
    console.log(value, "value")
    walletAccount = await web3.eth.requestAccounts();
    let balance = await web3.eth.getBalance(walletAccount[0]);
    let gasAmount = await GameContract.methods.claimOperonToken(walletAccount[0], value, data._mrs, data._v).estimateGas({ from: walletAccount[0] });
    console.log(gasAmount, "gasdetails")
    return {
        balance: balance,
        gasAmount: gasAmount,
    };
}