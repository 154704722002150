import axios from "../common-config/axios.config";
import { encryptString } from "../helper/bcrypt"



export const forgotpassword = async (data) => {
  try {
    let respData = await axios.post('users/forgot', data)
    return {
      result: JSON.parse(respData.data.result),
      message: respData.data.responseMessage,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const login = async (data) => {
  try {
    let respData = await axios.post('users/login', data)
    return {
      result: respData.data.result,
      tokenName: respData.data.tokenName,
      message: respData.data.responseMessage,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const resetpassword = async (data) => {
  try {
    data.password = await encryptString(data.password);
    data.confirmpassword = await encryptString(data.confirmpassword);
    let respData = await axios.post('users/reset', data)
    return {
      message: respData.data.responseMessage,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const checklink = async (data) => {
  try {
    let hash = JSON.stringify(data.hash)
    let address = data.address
    let respData = await axios.get(`users/checklink?verify=${hash}&address=${address}`)
    return {
      message: respData.data.responseMessage,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const profileupload = async (data) => {
  try {
    var formData = new FormData();
    if (data.file) {
      formData.append('Image', data.file);
    }
    formData.append('addr', data.currAddr);
    let respData = await axios.post('profile/update', formData)
    return {
      message: respData.data.message,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const claimtokens = async () => {
  try {
    let respData = await axios.get(`users/claimtoken`)
    return {
      message: respData.data.responseMessage,
      success: respData.data.success,
      result: respData.data.result,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};


export const finduser = async (data) => {
  try {
    let respData = await axios.post(`users/finduser`,data)
    return {
      message: respData.data.responseMessage,
      success: respData.data.success,
      result: respData.data.result,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};



export const userDetails = async () => {
  try {
    let respData = await axios.get(`users/getUserById`)
    return {
      message: respData.data.responseMessage,
      success: respData.data.success,
      result: respData.data.checkUser,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};



export const updateemailaddress = async (data) => {
  try {
    let respData = await axios.post(`users/updateEmail`, data)
    return {
      message: respData.data.message,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};

export const updatetoken = async (data) => {
  try {
    let respData = await axios.post('reward/add_rewards', data)
    return {
      result: JSON.parse(respData.data.result),
      message: respData.data.responseMessage,
      success: respData.data.success,
      err: respData.data.errors,
    };
  } catch (err) {
    return {
      err: returnErr(err),
    };
  }
};



function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}