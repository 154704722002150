export const getAuthToken = () => {
    if (sessionStorage.getItem('operon_token')) {
        return sessionStorage.getItem('operon_token')
    }
    return '';
}

export const removeAuthToken = () => {
    sessionStorage.removeItem('operon_token');
}

export const setAuthToken = (token) => {
    sessionStorage.setItem('operon_token', token);
    return true
}