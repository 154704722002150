import React, { useState, useEffect } from 'react'
import { Form, Alert, ModalTitle, Badge, Spinner } from "react-bootstrap";
import { connect } from 'react-redux'
import { onLoggingIn, OnLoggingOut } from '../../redux-store/actions';
import { userDetails, updateemailaddress,profileupload } from '../../APi/authactions'
import { toastr } from 'react-redux-toastr';
import ProfileLogo from "../../assets/images/profile-logo.png"
import { Scrollbars } from "react-custom-scrollbars";
import config from "../../common-config/config"
import copy from 'copy-to-clipboard'



var initdata = {
  "email": "",
  "address": "",
  "zoro_coins": 0,
  "energy_count": 0,
  "mediumlevel": "",
  "hardlevel": "",
}
const ProfileCompnent = (props) => {
  const [profileData, setprofileData] = useState(initdata);
  const [loading, setloading] = useState(false);
  const [email, setemail] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState("");
  const [TokenFile, setTokenFile] = React.useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState("");
  const [copytxt, setcopytxt] = useState(false)




  useEffect(() => {
    getDetails();
  }, [])

  const getDetails = (async () => {
    let { result } = await userDetails();
    setprofileData(result);
    console.log(result, "resulst")
  })

  const updateEmail = (async () => {
    setloading(true);
    let reqdata = { 'email': email }
    let { success, message } = await updateemailaddress(reqdata);
    if (success) {
      toastr.success(message);
    } else {
      toastr.error("update email failed");
    }
    setloading(false);
  })

  const onchangeEmail = ((e) => {
    let { value } = e.target;
    console.log(value)
    setemail(value);
  })

  const Onfileupload = (async(e) => {
    // "profile/update"
    if (e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 30) {
        toastr.error("File size exceeds 30 MB");
        // $("input[name='image']").val("");
        return false;
      } else {
        setTokenFile(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        setTokenFilePreUrl(e.target.value);
        let reqdata = {file: file,currAddr: profileData.address }
        let {success,message} = await profileupload(reqdata);
        if(success){
          toastr.success(message);
          getDetails();
        }else{
          toastr.warning(message);
        }
      }
    }
  })

  const copyaddress =() => {
    copy(profileData.address);
    toastr.success("Address copied successfully")
  }

  return (
    <div className="login-component">
      <div className="login-box">
        {/* <ModalTitle>My Profile</ModalTitle> */}
        <Scrollbars
          style={{ width: "100%", height: 470 }}
          renderTrackVertical={(props) => (
            <div className="track-vertical" />
          )}
        >
        <div className='walletBalance'>
          <div className='mb-4'>
            {/* <h3 className='wbTitle'>Change Profile Image</h3> */}
            <div className='edit-profile-group'>
              {profileData&&profileData.image ===""?<img src={ProfileLogo} alt="Logo" className="drop-profile_img" />:<img src={`${config.IMAGE_URL}profile/${profileData.image}`} alt="Logo" className="drop-profile_img" />}

              <label for="apply" className='edit-file-upload'><input type="file" onChange={Onfileupload} name="" id="apply" className='editIcon' /><i class="fa-solid fa-pencil" ></i></label>
            </div>
          </div>
          <div className='mb-4'>
            <h3 className='wbTitle'>Email</h3>
            {profileData && <h2>{profileData.email}</h2>}
          </div>
          <div className='mb-4'>
            <h3 className='wbTitle'>Address</h3>
            {profileData && <h2 className='addressFull'>{profileData.address} <i class="fa-regular fa-copy" onClick={copyaddress}></i></h2>}
          </div>
          <div className='mb-4'>
          <div className='level_div'>
          <h2>Available Token {profileData && <small className='d-block mt-2'>{profileData.zoro_coins} ZORO</small>}</h2>
            <h2>Energy {profileData && <small className='d-block mt-2'>{profileData.energy_count} ⚡</small>}</h2>
          </div>
          </div>
          <div className='mb-4'>
          <h3 className='wbTitle'>Level</h3>
          <div className='level_div'>
            <h2>Hard Level {profileData && <small className='d-block mt-2'>{profileData.hardlevel}</small>}</h2>
            <h2>Medium Level {profileData && <small className='d-block mt-2'>{profileData.mediumlevel}</small>}</h2>
          </div>
          </div>
        </div>
        <div className='changePassword'>
          <h3 className='pageTitle'>Change Email</h3>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter your New Email"
                value={email}
                onChange={onchangeEmail}
              />

            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
              />
                
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
              />
               
            </Form.Group> */}
            <button
              className="btn btn-warning"
              type="button"
              disabled={loading ? true : false}
              onClick={updateEmail}
            >
              {loading ? <Spinner animation="border" size='sm' variant="light" /> :
                "Change Email"}
            </button>
          </Form>
        </div>
        </Scrollbars>
    </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(onLoggingIn(data)),
    onLoggout: () => dispatch(OnLoggingOut())
  }
}





export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompnent);