import './login.css'
import { Form, Alert,ModalTitle,Button, } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { api } from '../../../common-config/axios.config'
import { useNavigate } from 'react-router-dom'
import { onLoggingIn } from '../../../redux-store/actions'
import { toastr } from 'react-redux-toastr'
import { connectToMetamask } from '../../../redux-store/actions'
import config from "../../../common-config/config";
import {forgotpassword} from '../../../APi/authactions'
import {forgotvalidate} from '../validation'
import {decryptString, encryptString, replaceSpecialCharacter} from '../../../helper/bcrypt'
import Logo from "../../../assets/images/logo-login.png";

const ForgotComponent = (props) => {
    const navigate = useNavigate()
    const [zoro, setzoro] = useState();
    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const [forgotlink, setforgotlink] = useState(false);
    const [antifishcode, setantifishcode] = useState();
    const [validation,setvallidation] = useState({})





    useEffect(() => {
        if (props.accounts.isConnected) {
            updateForm('address', props.accounts.address)
        }
    }, [props.accounts.isConnected])


    const submitForm = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(formData,"formdata...........");
        let validation = await forgotvalidate(formData)
        if(!validation.status){
            setvallidation(validation.errors)
            return false;
        }else{
            setvallidation({})
        }
        var {message,success,err,result} = await forgotpassword(formData)
        if(success){
            setforgotlink(true)
            setantifishcode(result.fishcode)
            toastr.success(message)
        }else{
            console.log(err);
            toastr.error(err)
        }
    }
    const reset =(async()=>{
        let hash = await encryptString(antifishcode,true);
        console.log(hash,"myhash")
        

        navigate(`/resetpassword/${formData.address}/${hash}`)
    })

    const updateForm = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    }
    return (
        <>
        
        <img src={Logo} alt="Logo" className="logo_img-login d-block mx-auto mb-4" />
        <div className="login-component">
            <div className='login-box'>
            <ModalTitle>Forgot Password</ModalTitle>
                <Form noValidate validated={validated} onSubmit={submitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="name" placeholder="Enter Email" onChange={e => updateForm('email', e.target.value)} />
                        {validation.email&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.email}</Form.FloatingLabel></div>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicWalletAddress">
                        <Form.Label>Wallet Address</Form.Label>
                        <Form.Control type="walletAddress"  onChange={e => updateForm('address', e.target.value)} value={props.accounts.address} />
                        {validation.address&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.address}</Form.FloatingLabel></div>}
                    </Form.Group>
                    {
                        !props.accounts.isConnected
                            ? <button className='btn btn-warning' type="button" onClick={() => props.onConnectToMetamask()} >
                                Connect To Metamask
                            </button>
                            : <button className={!props.accounts.isConnected ? 'btn btn-secondary cursor-not-allowed' : 'btn btn-secondary'} type="submit" disabled={!props.accounts.isConnected}>
                                Submit
                            </button>
                    }
                    <Form.Group> 
                    {forgotlink?   
                        <Form.Text muted className='d-block' style={{ fontSize: '17px', letterSpacing: '0.5px' }}>
                           Click This button To Reset your password <Button className='btn btn-warning' onClick={reset}>Reset</Button>
                        </Form.Text>:!props.accounts.isConnected?
                        <Form.Text muted className='d-block' style={{ fontSize: '17px', letterSpacing: '0.5px' }}>
                           Please Connect To Metamask Wallet first!
                        </Form.Text>:<span></span>}
                    </Form.Group>
                </Form>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        accounts: state.reducer.account || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (data) => dispatch(onLoggingIn(data)),
        onConnectToMetamask: () => dispatch(connectToMetamask())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotComponent)