import { isMetaMaskInstalled } from '../web3/web3';
import * as actions from './types'
import { toastr } from 'react-redux-toastr';

export const connectToMetamask = () => {
    console.log('inn');
    if (window.ethereum) {
        return async dispatch => {
            console.log(dispatch);
            const result = await isMetaMaskInstalled(dispatch)
            console.log("----result",result)
            if (result) {
                toastr.success("", result.notification.message)
                return dispatch(onConnectToMetask(result.account))
            }
        }
    } else {
        return async dispatch => {
        toastr.error("Please install metamask extension")
        return dispatch(onConnectToMetask(""))
        }
    }
}

// export const onConnectToMetask = (data) => (
//     {
//         type: actions.ON_CONNECT_WALLET,
//         payload: { 
//             data
//         }
//     });

export const onConnectToMetask = (data) => {
    console.log('onConnectToMetaskonConnectToMetask',data)
    return {
        type: actions.ON_CONNECT_WALLET,
        payload: {
            data
        }
    }
};

export const onLoggingIn = (data) => ({
    type: actions.ON_LOGGING_IN,
    payload: {
        data
    }
})

export const OnLoggingOut = () => (
    {
        type: actions.ON_LOGGING_OUT
    })