import './login.css'
import { Form, Alert,ModalTitle,Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { api } from '../../../common-config/axios.config'
import { useNavigate, useParams } from 'react-router-dom'
import { onLoggingIn } from '../../../redux-store/actions'
import { toastr } from 'react-redux-toastr'
import { connectToMetamask } from '../../../redux-store/actions'
import config from "../../../common-config/config";
import {resetpassword,checklink} from '../../../APi/authactions'
import { replaceSpecialCharacter,decryptString } from '../../../helper/bcrypt'
import {Resetvalidate} from '../validation'

const ForgotComponent = (props) => {
    const navigate = useNavigate()
    const {address,fishcode} = useParams()
    const [zoro, setzoro] = useState();
    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const [verified, setverified] = useState(false);
    const [validation,setvallidation] = useState({})





    useEffect(()=>{
        verifylink();
        if (props.accounts.isConnected) {
            updateForm('address', props.accounts.address)
        }
    },[props.accounts.isConnected])


    const verifylink=(async()=>{
        let hash = await decryptString(fishcode,true);
        console.log(hash,"myhash2")
        let reqdata = {address:address,hash:hash}
        let {message,success,err} = await checklink(reqdata);
        if(success){
            setverified(true)
        }else{
            setverified(false)
            toastr.error(err)
            navigate('/forgot')
        }

    })


    const submitForm = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(formData,"formdata...........");
        setvallidation({});
        let check = await Resetvalidate(formData)
        console.log(check,"check")
        if(!check.status){
            setvallidation(check.errors)
            return false;
        }
        var {message,success,err,result} = await resetpassword(formData)
        if(success){
            toastr.success(message)
            navigate('/login');
        }else{
            console.log(err);
            toastr.error(err)
            navigate('/forgot');
        }
    }

    const updateForm = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    }
    return (
        <div className="login-component">
            <div className='login-box'>
            <ModalTitle>Reset Password</ModalTitle>
                <Form noValidate validated={validated} onSubmit={submitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter New password" onChange={e => updateForm('password', e.target.value)} />
                        {validation.password&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.password}</Form.FloatingLabel></div>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="name" placeholder="Enter confirm password" onChange={e => updateForm('confirmpassword', e.target.value)} />
                        {validation.confirmpassword&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.confirmpassword}</Form.FloatingLabel></div>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicWalletAddress">
                        <Form.Label>Wallet Address</Form.Label>
                        <Form.Control type="walletAddress" onChange={e => updateForm('address', e.target.value)} value={props.accounts.address} />
                        {validation.address&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.address}</Form.FloatingLabel></div>}
                        {validation.comman&&<div className='alert alert-danger'><Form.FloatingLabel>{validation.comman}</Form.FloatingLabel></div>}
                    </Form.Group>
                    {
                        !props.accounts.isConnected
                            ? <button className='btn btn-warning' type="button" onClick={() => props.onConnectToMetamask()} >
                                Connect To Metamask
                            </button>
                            : <button className={!props.accounts.isConnected ? 'btn btn-secondary cursor-not-allowed' : 'btn btn-secondary'} type="submit" disabled={!props.accounts.isConnected}>
                                Reset
                            </button>
                    }
                    <Form.Group> 
                        {!props.accounts.isConnected&&<Form.Text muted className='d-block' style={{ fontSize: '17px', letterSpacing: '0.5px' }}>
                           Please Connect To Metamask Wallet first!
                        </Form.Text>}
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        accounts: state.reducer.account || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (data) => dispatch(onLoggingIn(data)),
        onConnectToMetamask: () => dispatch(connectToMetamask())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotComponent)