import "./login.css";
import { Form, Alert, ModalTitle, Badge, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { onLoggingIn } from "../../../redux-store/actions";
import { toastr } from "react-redux-toastr";
import { connectToMetamask } from "../../../redux-store/actions";
import config from "../../../common-config/config";
import { Loginvalidate } from "../validation";
import { login, claimtokens, finduser } from "../../../APi/authactions";
import { claimToken, isMetaMaskInstalled } from "../../../web3/web3";
import { setAuthorization } from "../../../common-config/axios.config";
import Logo from "../../../assets/images/logo-login.png";


var initdata = {
  "email": "",
  "password": "",
  "address": ""
}

const LoginComponent = (props) => {
  const navigate = useNavigate();
  const [zoro, setzoro] = useState();
  const [email, setemail] = useState();
  const [validated, setValidated] = useState(false);
  const [validation, setvallidation] = useState({});
  const [loading, setloading] = useState(false);
  const [walletloading, setwalletloading] = useState(false)
  const [isregister, setisregister] = useState(false);


  useEffect(() => {
    
    if (window.ethereum) {
      if (props.accounts.isConnected) {
        updateForm("address", props.accounts.address);
        if (walletloading) {
          setEmail()
        }
      }
    }
  }, [props.accounts.isConnected]);

  const claim = async () => {
    try {
      setloading(true);
      let { success, result, message } = await claimtokens();
      if (success) {
        let { sign, zoro } = JSON.parse(result);
        console.log(sign, "....sign");
        let signdata = {
          _mrs: sign._mrs,
          _v: sign._v,
          amount: zoro,
        };
        console.log(signdata, "signdata");
        let myclaim = await claimToken(signdata);
        console.log(myclaim, "contract result");
        setzoro(0);
        setloading(false);
        toastr.success("Rewards claimed successfully!!!");
      } else {
        setloading(false);
        toastr.error(message);
      }
    } catch (err) {
      setloading(false);
      toastr.error("Rewards claimed failed!!!");
      console.log(err, "error");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(isregister, "isregister")
    setvallidation({});
    let valid = await Loginvalidate(formData);
    if (!valid.status) {
      setvallidation(valid.errors);
      return;
    }
    let { err, success, result, tokenName } = await login(formData);
    if (err) {
      setvallidation(err);
      if (isregister) {
        toastr.error("you're already register this address");
      } else {
        toastr.error("Login Failed");
      }
      return false;
    }
    props.onLogin({ isLoggedIn: true, ...result });
    setAuthorization(tokenName);
    sessionStorage.setItem("operon_token", tokenName);
    sessionStorage.setItem("zoro", result.zoro);
    navigate("/home");
    console.log(result.zoro, "zoro");
  };

  const [formData, setFormData] = useState(initdata);
  const updateForm = async (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const setEmail = async () => {
    let reqdata = { address: props.accounts.address };
    let { result } = await finduser(reqdata);
    if (result) {
      setemail(result);
      let formValue = {
        ...formData,
        ...{ 'email': result,'address': props.accounts.address },
      }
      setFormData(formValue);
    }
    setwalletloading(false);
  }

  const connectwallet = (async () => {
    if(window.ethereum){

    setwalletloading(true)
    await props.onConnectToMetamask()
    setwalletloading(false);
  }else{
    toastr.error("Please install metamask extension")
  }
  })
  console.log(formData, "formdata")
  return (
    <>
      <img src={Logo} alt="Logo" className="logo_img-login d-block mx-auto mb-4" />
      <div className="login-component">
        <div className="login-box">
          <ModalTitle>Login</ModalTitle>
          <Form noValidate validated={validated} onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Email"
                value={formData.email}
                onChange={(e) => updateForm("email", e.target.value)}
              />
              {validation.email && (
                <div className="alert alert-danger">
                  <Form.FloatingLabel>{validation.email}</Form.FloatingLabel>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                onChange={(e) => updateForm("password", e.target.value)}
              />
              {validation.password && (
                <div className="alert alert-danger">
                  <Form.FloatingLabel>{validation.password}</Form.FloatingLabel>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicWalletAddress">
              <Form.Label>Wallet Address</Form.Label>
              <Form.Control
                type="walletAddress"
                // placeholder="Wallet Address"
                onChange={(e) => updateForm("address", e.target.value)}
                value={props.accounts.address}
              />
              {validation.address && (
                <div className="alert alert-danger">
                  <Form.FloatingLabel>{validation.address}</Form.FloatingLabel>
                </div>
              )}
            </Form.Group>
            {!props.accounts.isConnected ? (
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => connectwallet()}
              >
                {walletloading && <Spinner animation="border" size="sm" />}
                Connect To Metamask
              </button>
            ) : (
              <div className="login_btn_grps">
                {email ? <button
                  name="login"
                  className={
                    !props.accounts.isConnected
                      ? "btn btn-secondary cursor-not-allowed"
                      : "btn btn-secondary"
                  }
                  type="submit"
                  disabled={!props.accounts.isConnected}
                >
                  Login
                </button> :
                  <button
                    name="register"
                    onClick={() => setisregister(true)}
                    className={
                      !props.accounts.isConnected
                        ? "btn btn-secondary cursor-not-allowed"
                        : "btn btn-secondary"
                    }
                    type="submit"
                    disabled={!props.accounts.isConnected}
                  >
                    Register
                  </button>}
              </div>
            )}
            <Form.Group>
              {!props.accounts.isConnected && <Form.Text
                muted
                className="d-block"
                style={{ fontSize: "17px", letterSpacing: "0.5px" }}
              >
                Please Connect To Metamask Wallet first!{" "}
              </Form.Text>}
              <Form.Text className="d-block linkText"
                style={{ fontSize: "17px", letterSpacing: "0.5px", textAlign: "center" }}><Link to={"/forgot"}>Forgot Password</Link>
              </Form.Text>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(onLoggingIn(data)),
    onConnectToMetamask: () => dispatch(connectToMetamask()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
