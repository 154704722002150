import { Suspense,useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginComponent from './container/components/login/login';
import ProfileCompnent from './container/components/profile';
import ForgotComponent from './container/components/login/forgotpassword';
import ResetComponent from './container/components/login/resetpassword';
import HomeComponent from './container/components/home'
import Layout from './container/hoc/layout/Layout';
import AuthLayout from './container/hoc/Initlayout/AuthLayout'
import { connect } from 'react-redux'
import ConditionRoute from './Auth/ConditionRoute';

import { onLoggingIn, OnLoggingOut } from './redux-store/actions';
import { toastr } from 'react-redux-toastr';
import Web3 from 'web3';
import config from "./common-config/config"
import {subscribeToAccountNetworkChange} from "./web3/web3"

// const checkNetwork=(()=>{
//   if(window.ethereum){
//   }else{
// let web3 = new Web3(window.ethereum);
// web3.eth.net.getId((err, netId) => {
//   if(netId!==config.NETWORK){
//     toastr.warning("please switch binance Mainnet")
//   }
// })
//   }
// })



const App = (props) => {


  useEffect(()=>{
    if(window.ethereum){
    subscribeToAccountNetworkChange(mapDispatchToProps,config.NETWORK)
  }else{
    toastr.warning("please Add metamask extension");
  }
  },[]) 
  // checkNetwork();
  // if(window.ethereum){
  //   subscribeToAccountNetworkChange(mapDispatchToProps,config.NETWORK)
  // }else{
  //   toastr.warning("please Add metamask extension");
  // }
  console.log(process.env.NODE_ENV,"environment")
  return (
    <div className="App">
      <Suspense fallback={null}>
        <Routes>
            <Route path="/home" element={<ConditionRoute type="private"><Layout><HomeComponent /></Layout></ConditionRoute>}></Route>
            <Route path="/login" element={<ConditionRoute type="public"><AuthLayout><LoginComponent /></AuthLayout></ConditionRoute>}></Route>
            <Route path="/profile" element={<ConditionRoute type="public"><Layout><ProfileCompnent /></Layout></ConditionRoute>}></Route>
            <Route path="/forgot" element={<ConditionRoute type="public"><AuthLayout><ForgotComponent /></AuthLayout></ConditionRoute>}></Route>
            <Route path="/resetpassword/:address/:fishcode" element={<ConditionRoute type="public"><AuthLayout><ResetComponent /></AuthLayout></ConditionRoute>}></Route>
            <Route path="/web-desktop"></Route>
            <Route path="/*" element={<ConditionRoute type="public"><AuthLayout><LoginComponent /></AuthLayout></ConditionRoute>}></Route>
        </Routes> 
      </Suspense>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(onLoggingIn(data)),
    onLoggout: () => dispatch(OnLoggingOut())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
