import React, { useState } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { claimtokens } from '../../APi/authactions';
import { claimToken } from '../../web3/web3';
import config from '../../common-config/config'
import {  Badge } from 'react-bootstrap'
import { connectToMetamask, onLoggingIn } from '../../redux-store/actions'





const HomeCompnent = (props) => {
    const [zoro, setzoro] = useState();

    const claim = (async () => {
        let { success, result, message } = await claimtokens();
        if (success) {
            let { sign, zoro } = JSON.parse(result);
            console.log(sign, "....sign")
            let signdata = {
                _mrs: sign._mrs,
                _v: sign._v,
                amount: zoro
            }
            console.log(signdata, "signdata");
            let myclaim = await claimToken(signdata);
            console.log(myclaim, "contract result")
        } else {
            toastr.error(message)
        }
    })

    return (
        <div>
            {zoro && <div className='token-copy' onClick={claim}><Badge bg="success">{zoro} ZORO</Badge>{' '}</div>}
            <div className='text-center mt-3'>
                <a href={`${config.GameUrl}?token=${sessionStorage.getItem('operon_token')}`} className='btn btn-warning px-5 py-3 d-flex align-items-center'>Play Game (Alpha Version)</a>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        accounts: state.reducer.account || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (data) => dispatch(onLoggingIn(data)),
        onConnectToMetamask: () => dispatch(connectToMetamask())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeCompnent)